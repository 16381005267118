<template>
  <div id="org">
    <Table />
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import Table from "./table";
export default {
  mixins: [part],
  name: "org",
  components: {
    Table,
  },
};
</script>

<style>
</style>