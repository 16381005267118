<template>
  <div id="export">
    <el-dialog
      class="btn-2b5a95"
      title="导出字典数据"
      :visible.sync="exportDialog"
      width="40%"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      append-to-body
    >
      <div class="cancel" @click="cancel" />
      <el-transfer
        v-model="rightData"
        :data="leftData"
        :titles="['全选', '全选']"
      />
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button @click="exportData">导 出</el-button>
        <el-button @click="confirm">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { myRequest } from "@/request/index";

import { mapActions } from "vuex";
export default {
  mixins: [part],
  name: "Export",
  props: {
    exportDialog: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      leftData: [], // 左边数据
      rightData: [], // 右边数据
      leftId: [], // 存储左边的id
      from: {}, // 接受父组件传过来的值
    };
  },
  mounted() {
    this.getData();
  },

  methods: {
    ...mapActions("global", ["onExtendClick"]),
    // 初始化
    getData() {
      myRequest({
        url: "/module/export.do",
      }).then((res) => {
        if (res.status === 200) {
          // 左边数据
          this.leftData = [];
          var leftDataId = [];
          var metadatalist1 = res.data.data.metadatalist1;
          metadatalist1.forEach((item) => {
            this.leftData.push({
              key: item.id,
              label: item.cnpropertyname,
            });
            leftDataId.push(item.id);
          });

          // 右边数据
          this.rightData = [];
          var metadatalist2 = res.data.data.metadatalist2;
          metadatalist2.forEach((content, index) => {
            this.rightData.push(content.id);
          });
          // 比较两边的数据, 得出左边未选择的数据
          this.leftId = leftDataId.filter(
            (item) => this.rightData.indexOf(item) == -1
          );
        }
      });
    },

    // 取消
    cancel() {
      this.$emit("exportCancel");
    },

    // 导出数据
    exportData() {
      myRequest({
        method: "post",
        url: "/module/exportexcel.do",
        data: this.$qs.stringify(
          {
            ids: this.rightData,
          },
          { arrayFormat: "repeat" }
        ),
      })
        .then((res) => {
          if (res.status === 200) {
            location.href =
              this.$url.Freemarker +
              `/module/exportexcel.do?ids=${this.rightData}`;
            this.$message({
              message: "导出成功",
              type: "success",
            });
            this.$emit("exportCancel");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 确定
    confirm() {
      myRequest({
        url: "/module/exportsave.do",
        method: "post",
        data: this.$qs.stringify(
          {
            ids1: this.leftId,
            ids2: this.rightData,
          },
          { arrayFormat: "repeat" }
        ),
      })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code == 200) {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
            } else {
              this.$message({
                message: "请确保右边有值",
                type: "info",
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
#export /deep/ .el-transfer {
  padding: 9%;
}
#export /deep/ .el-transfer .el-transfer-panel {
  height: 350px;
}
</style>
