<template>
  <div id="module">
    <div class="Btn">
      <el-button
        v-for="(item, index) in buttonlist"
        :key="index"
        type="primary"
        @click="select(item.buttonname)"
        >{{ item.name }}</el-button
      >
    </div>
    <el-tree
      :data="listData"
      :props="defaultProps"
      node-key="id"
      :empty-text="emptyText"
      @node-click="handleNodeClick"
    />
    <LevelModules
      ref="levelData"
      :level-dialog="LevelDialog"
      @LevelCancel="LevelCancel"
      @LevelConfirm="LevelConfirm"
    />
    <levelExport :export-dialog="exportDialog" @exportCancel="exportCancel" />
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { myRequest } from "@/request/index";

import { mapActions } from "vuex";
import LevelModules from "./levelMoudle";
import levelExport from "./levelExport";
export default {
  mixins: [part],
  inject: ["reload"],
  name: "Module",
  components: {
    LevelModules,
    levelExport,
  },
  data() {
    return {
      emptyText: "加载中...", // tree树显示的文本
      buttonlist: [], // 按钮数组
      listData: [], // tree数组
      defaultProps: {
        children: "children",
        label: "label",
      },
      nodeId: null, // 点击的id
      LevelDialog: false, // 顶级, 下级, 修改弹框
      exportDialog: false, // 导出
      parentId: 0,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    ...mapActions("global", ["onExtendClick"]),
    // 初始化按钮
    initButton() {
      this.buttonlist = [
        {
          id: 1,
          name: "增加顶级机构",
          buttonname: "btnAdd0",
        },
        {
          id: 2,
          name: "增加下级机构",
          buttonname: "btnAdd1",
        },
        {
          id: 3,
          name: "修改",
          buttonname: "btnEdit",
        },
        {
          id: 4,
          name: "删除",
          buttonname: "btnDel",
        },
        {
          id: 5,
          name: "启用",
          buttonname: "btnEnable1",
        },
        {
          id: 6,
          name: "禁用",
          buttonname: "btnEnable0",
        },
        {
          id: 7,
          name: "刷新",
          buttonname: "btnReload",
        },
      ];
    },

    // 获取树形数据
    getData() {
      this.listData = [];
      myRequest({
        url: "/org/treedata.do",
        method: "post",
      }).then((res) => {
        this.listData = [];
        if (res.status === 200) {
          // 按钮
          this.initButton();
          var listData = res.data.data.dhtmlxtree;
          var item1 = listData.item;
          // 遍历第一层数据
          item1.forEach((content1) => {
            var list = {
              id: content1.id,
              label: content1.text,
              children: [],
            };

            // 遍历第二层数据
            var item2 = content1.item;
            item2.forEach((content2) => {
              var list2 = {
                id: content2.id,
                label: content2.text,
                children: [],
              };
              list.children.push(list2);
              // 遍历第三层数据
              var item3 = content2.item;
              item3.forEach((content3) => {
                var list3 = {
                  id: content3.id,
                  label: content3.text,
                  children: [],
                };
                list2.children.push(list3);
                // 遍历第四层数据
                var item4 = content3.item;
                item4.forEach((content4) => {
                  var list4 = {
                    id: content4.id,
                    label: content4.text,
                    children: [],
                  };
                  list3.children.push(list4);
                });
              });
            });
            // 最终将listpush进数组
            this.listData.push(list);
            if (this.listData == "") {
              this.emptyText = "暂无内容";
            }
          });
        }
      });
    },

    // 点击节点获取id
    handleNodeClick(e, node) {
      this.nodeId = e.id;
      if (node.parent.id != 0) {
        this.parentId = node.parent.data.id;
      }
    },

    // 顶级, 下级, 修改弹框取消
    LevelCancel() {
      this.LevelDialog = false;
    },

    // 顶级, 下级, 修改弹框确定
    LevelConfirm() {
      this.LevelDialog = false;
    },

    // 导出取消
    exportCancel() {
      this.exportDialog = false;
    },

    // 按钮
    select(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ...
    },

    // 增加顶级机构
    btnAdd0() {
      this.LevelDialog = true;
      this.$refs.levelData.init(0, this.nodeId);
    },

    // 增加下级机构
    btnAdd1() {
      if (this.nodeId != null) {
        this.LevelDialog = true;
        this.$refs.levelData.init(1, this.nodeId);
      } else {
        this.$message({
          message: "请选择节点",
          type: "info",
        });
      }
    },

    // 修改
    btnEdit() {
      var node = {
        nodeId: this.nodeId,
        parentId: this.parentId,
      };
      if (this.nodeId != null) {
        this.LevelDialog = true;
        this.$refs.levelData.init(2, node);
      } else {
        this.$message({
          message: "请选择节点",
          type: "info",
        });
      }
    },

    // 删除
    btnDel() {
      if (this.nodeId != null) {
        this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            myRequest({
              url: "/org/delete.do",
              method: "post",
              data: this.$qs.stringify(
                {
                  ids: [this.nodeId],
                },
                { arrayFormat: "repeat" }
              ),
            })
              .then((res) => {
                if (res.status === 200) {
                  if (res.data.code == 0) {
                    this.$message({
                      type: "info",
                      message: res.data.msg,
                    });
                  } else {
                    this.$message({
                      type: "success",
                      message: res.data.msg,
                    });
                    // this.reload()
                  }
                }
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },

    // 启用
    btnEnable1() {
      if (this.nodeId != null) {
        myRequest({
          url: "/org/enable.do",
          method: "post",
          data: this.$qs.stringify(
            {
              id: [this.nodeId],
              enable: 1,
            },
            { arrayFormat: "repeat" }
          ),
        })
          .then((res) => {
            if (res.status === 200) {
              if (res.data.code == 200) {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "warning",
                });
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$message({
          message: "请选择节点",
          type: "info",
        });
      }
    },

    // 禁用
    btnEnable0() {
      if (this.nodeId != null) {
        myRequest({
          method: "post",
          url: "/org/enable.do",
          data: this.$qs.stringify(
            {
              id: [this.nodeId],
              enable: 0,
            },
            { arrayFormat: "repeat" }
          ),
        })
          .then((res) => {
            if (res.status === 200) {
              if (res.data.code == 200) {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "warning",
                });
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$message({
          message: "请选择节点",
          type: "info",
        });
      }
    },

    // 刷新
    btnReload() {
      this.getData();
    },
  },
};
</script>

<style>
/* 第一行按钮 */
.Btn {
  display: flex;
  flex-flow: wrap;
}
.Btn /deep/ .el-button {
  margin: 20px;
  margin-right: 0;
}
</style>