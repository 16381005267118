<template>
  <div id="level">
    <el-dialog
      class="btn-2b5a95"
      :title="title"
      :visible.sync="LevelDialog"
      width="33%"
      center
			v-dialogDrag
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      append-to-body
    >
      <div class="cancel" @click="cancel" />
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="上级机构">{{ form.parent }}</el-form-item>
        <el-form-item label="深度">{{ form.deepth }}</el-form-item>
        <el-form-item label="机构编号">
          <el-input v-model.trim="form.code" clearable/>
        </el-form-item>
        <el-form-item label="机构简称">
          <el-input v-model.trim="form.shortname" clearable/>
        </el-form-item>
        <el-form-item label="机构全称">
          <el-input v-model.trim="form.fullname" clearable/>
        </el-form-item>
				<el-form-item label="是否教学点">
				  <el-input v-model.trim="form.extraflag" clearable/>
				</el-form-item>
        <el-form-item label="是否可用">
          <el-input v-model.trim="form.enableflag" clearable/>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model.trim="form.remark" clearable/>
        </el-form-item>
        <el-form-item label="排序权重">
          <el-input v-model.trim="form.rank" clearable/>
        </el-form-item>
        <el-form-item label="机构地址">
          <el-input v-model.trim="form.addr" clearable/>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model.trim="form.phone" clearable/>
        </el-form-item>
        <el-form-item label="传真">
          <el-input v-model.trim="form.fax" clearable/>
        </el-form-item>
        <el-form-item label="虚拟机构">
          <el-input v-model="form.virtual" disabled />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button @click="confirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { myRequest } from "@/request/index";

import { mapActions } from "vuex";
export default {
  mixins: [part],
  name: "Level",
  props: {
    LevelDialog: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      title: null,
      form: {},
    };
  },

  methods: {
    ...mapActions("global", ["onExtendClick"]),
    // 初始化
    init(index, val) {
      var params = {};
      if (index === 0) {
        params = {
          parentid: 0,
          id: 0,
        };
        this.title = "增加顶级模块";
      } else if (index === 1) {
        params = {
          parentid: val,
          id: 0,
        };
        this.title = "增加下级模块";
      } else if (index === 2) {
        params = {
          parentid: val.parentId,
          id: val.nodeId,
        };
        this.title = "修改";
      }

      // 请求数据
      myRequest({
        url: "/org/input.do",
        data: params,
      })
        .then((res) => {
          if (res.status === 200) {
            this.form = res.data.data.torg;
            this.form.parentid = res.data.data.parentid;
            this.form.parent = res.data.data.parent;
            this.form.deepth = res.data.data.deepth;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 选择按钮操作
    selectButton(e) {
      this.operationList.forEach((item) => {
        // 选择模块的名字, 顺带关联选择按钮名称
        if (item.name === e) {
          this.form.name = item.name;
          this.form.buttonname = item.nikeName;
        }
      });
    },

    // 退出
    cancel() {
      this.$emit("LevelCancel");
    },

    // 确定
    confirm() {
      myRequest({
        url: "/org/save.do",
        data: {
          id: this.form.id, // 流水号
          fax: this.form.fax, // 传真
          code: this.form.code, // 机构编号
          rank: this.form.rank, // 排序权重
          addr: this.form.addr, // 机构地址
          phone: this.form.phone, // 联系电话
          remark: this.form.remark, // 备注
          deepth: this.form.deepth, // 深度
          virtual: this.form.virtual, // 虚拟机构
          fullname: this.form.fullname, // 机构全称
          parentid: this.form.parentid, // 父亲节点id
          shortname: this.form.shortname, // 机构简称
          enableflag: this.form.enableflag, // 是否启用
					extraflag: this.form.extraflag, //教学点
        },
      }).then((res) => {
        if (res.status === 200) {
          if (res.data.code == 200) {
            this.$emit("LevelConfirm");
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.moduleForm {
  display: flex;
}

.moduleForm >>> .el-input,
.moduleForm >>> .el-select {
  width: 50%;
}

.moduleForm >>> .el-input--suffix {
  width: 100%;
}

.moduleForm >>> .el-input--suffix {
  margin-left: 10px;
}
</style>
